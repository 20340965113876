<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'287px'" [hasNonOverflowContent]="true" *ngIf="!deviceFactory.isSmallScreen()">
  <a additionalActionsRight class="dm-link dm-tile--m-b-24" [uiSref]="RouteName.EngagementInvoices"
    (click)="logInvoiceClick()" *ngIf="!isComponentLoading && !showCaption">
    Go to Invoices
  </a>
  <dm-loading loader *ngIf="isComponentLoading" [isCustomText]="true" [loadingText]="loadingText"
    [showLoading]="showLoading"></dm-loading>
  <section class="col-md-12 col-sm-12 col-xs-12 dm-tile--no-pad dm-invoice-table-data table-text-spacing">
    <section class="col-lg-12 pad0">
      <ng-container *ngIf="!isComponentLoading && invoiceList && invoiceList.length">
        <div id="invoiceCaption" class="sr-only" aria-live="polite" style="caption-side: top">
          {{tableCaption}}
        </div>
        <table class="invoices-list" aria-labelledby="invoiceCaption" *ngIf="invoiceList && invoiceList.length > 0">
          <thead>
            <tr class="font-caption-alt invoices-top-header">
              <th class="text-left" scope="col">
                <div class="dminvoice-flex">
                  <button class="text-left" (click)="invoiceSort(SortOptions.DocumentNumber)" type="button"
                    role="button"
                    [attr.aria-label]="((sortVal===SortOptions.DocumentNumber) ? '' : 'Sort by' ) + 'Document Number ' + ((sortVal===SortOptions.DocumentNumber) ? ('sorted in'+ sortOrder + 'order') : '' )">
                    <span class="header-title">
                      <span>Document</span>
                      <br>
                      <span>Number</span>
                    </span>
                    <i class="icon l-h-25 w-d-20 color-black"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.DocumentNumber"></i>
                  </button>
                </div>
              </th>
              <th scope="col">
                <div class="dminvoice-flex">
                  <button class="text-right" (click)="invoiceSort(SortOptions.DocumentType)" type="button" role="button"
                    [attr.aria-label]="((sortVal===SortOptions.DocumentType) ? '' : 'Sort by ') + 'DocumentType ' + ((sortVal===SortOptions.DocumentType) ? ('sorted in '+ sortOrder + ' order') : '')">
                    <span class="header-title">
                      <span>Document</span>
                      <br>
                      <span class="m-r-3">Type</span>
                    </span>
                    <i class="icon l-h-25 w-d-20 color-black"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.DocumentType"></i>
                  </button>
                </div>
              </th>
              <th class="text-right" scope="col">
                <div class="dminvoice-flex dminvoice-flex--width-110">
                  <button class="dm-tile--m-r-4" type="button" (click)="invoiceSort(SortOptions.InvoiceTotal)"
                    role="button"
                    [attr.aria-label]="((sortVal===SortOptions.InvoiceTotal) ? '' : 'Sort by ') + 'InvoiceTotal ' + ((sortVal===SortOptions.InvoiceTotal) ? ('sorted in '+ sortOrder + ' order') : '' )">
                    <span class="header-title">Invoice Total</span>
                  </button>
                  <i class="icon color-black w-d-20 pull-right dm-tile--p-l-6"
                    [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                    *ngIf="sortVal===SortOptions.InvoiceTotal"></i>
                  <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                    [ngbTooltipContent]="'Invoiced amount is the total amount of the invoice. This may include the total amount of all projects on the invoice, and may not match total amount invoiced for a specific project'"
                    [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Invoice Total Information">
                  </dm-tooltip>
                  <span class="sr-only">press tab to navigate to tooltip button</span>
                </div>
              </th>
              <th scope="col">
                <div class="dminvoice-flex">
                  <button type="button" (click)="invoiceSort(SortOptions.Currency)" role="button"
                    [attr.aria-label]="((sortVal===SortOptions.Currency) ? '' : 'Sort by ') + 'Currency ' + ((sortVal===SortOptions.Currency) ? ('sorted in'+ sortOrder + ' order') : '' )">
                    <span class="header-title">Currency</span>
                    <i class="icon color-black w-d-20"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.Currency"></i>
                  </button>
                </div>
              </th>
              <th scope="col">
                <div class="dminvoice-flex">
                  <button type="button" (click)="invoiceSort(SortOptions.Status)" role="button"
                    [attr.aria-label]="((sortVal===SortOptions.Status) ? '' : 'Sort by') + 'Status ' + ((sortVal===SortOptions.Status) ? ('sorted in'+ sortOrder+ ' order') : '' )">
                    <span class="header-title">Status</span>
                    <i class="icon color-black w-d-20"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.Status"></i>
                  </button>
                </div>
              </th>
              <th scope="col">
                <div class="dminvoice-flex">
                  <button type="button" (click)="invoiceSort(SortOptions.PeriodStart)" role="button"
                    [attr.aria-label]="((sortVal===SortOptions.PeriodStart) ? '' : 'Sort by ') +'Period Start ' + ((sortVal===SortOptions.PeriodStart) ? ('sorted in'+ sortOrder+ ' order') : '' )">
                    <span class="header-title">Period Start</span>
                    <i class="icon color-black w-d-20"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.PeriodStart"></i>
                  </button>
                </div>
              </th>
              <th scope="col">
                <div class="dminvoice-flex">
                  <button type="button" (click)="invoiceSort(SortOptions.PeriodEnd)" role="button"
                    [attr.aria-label]="((sortVal===SortOptions.PeriodEnd) ? '' : 'Sort by ') + 'Period End ' + ((sortVal===SortOptions.PeriodEnd) ? ('sorted in'+ sortOrder + ' order') : '' )">
                    <span class="header-title">Period End</span>
                    <i class="icon color-black  w-d-20"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.PeriodEnd"></i>
                  </button>
                </div>
              </th>
              <th scope="col">
                <div class="dminvoice-flex">
                  <button type="button" (click)="invoiceSort(SortOptions.PaidDate)" role="button"
                    [attr.aria-label]="((sortVal===SortOptions.PaidDate) ? '' : 'Sort by ') +'Paid Date ' + ((sortVal===SortOptions.PaidDate) ? ('sorted in'+ sortOrder + ' order')  : '' )">
                    <span class="header-title">Paid Date</span>
                    <i class="icon color-black w-d-20"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.PaidDate"></i>
                  </button>
                </div>
              </th>
              <th scope="col">
                <div class="dminvoice-flex">
                  <button type="button" (click)="invoiceSort(SortOptions.Timestamp)" role="button"
                    [attr.aria-label]="((sortVal===SortOptions.Timestamp) ? '' : 'Sort by ') + 'Timestamp ' + ((sortVal===SortOptions.Timestamp) ? ('sorted in'+ sortOrder + ' order') : '' )">
                    <span class="header-title">Timestamp (PST)</span>
                    <i class="icon color-black  w-d-20"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.Timestamp"></i>
                  </button>
                </div>
              </th>
              <th scope="col" class="maxwidth-220">
                <div class="dminvoice-flex">
                  <button type="button" (click)="invoiceSort(SortOptions.Project)" role="button"
                    [attr.aria-label]="((sortVal===SortOptions.Project) ? '' : 'Sort by ') + 'Project ' + ((sortVal===SortOptions.Project) ? ('sorted in'+ sortOrder+ ' order') : '' )">
                    <span class="header-title">Project</span>
                    <i class="icon color-black w-d-20"
                      [ngClass]="{'icon-down-arrow' : !sortAscy, 'icon-up-arrow' : sortAscy}"
                      *ngIf="sortVal===SortOptions.Project"></i>
                  </button>
                </div>
              </th>
              <th class="col-wdth-44" scope="col" aria-label="Details" tabindex="0">
                  Details
              </th>
            </tr>
          </thead>
          <tbody class="dminvoicebody"
            *ngFor="let invoices of invoiceList
                                | filter: (selectedStatus ? {status: selectedStatus} : selectedStatus)
                                | filter: (invoiceNumber? {invoiceNumber: invoiceNumber}: invoiceNumber)
                                | slice:0: (limitInvoices !== -1 ? limitInvoices : (invoiceList ? invoiceList.length : 0))">
            <tr class="font-caption-alt" [ngClass]="{'demands-active-header':invoices.resourceAccordionFlag}">
              <td class="text-left" *ngIf="isCurrentUserPartOfTeamStructure" (click)="logDocListItemClick()">
                <a [href]="invoices.invoiceNumberLink" *ngIf="invoices.status === 'Parked'"
                  [attr.aria-label]="'Document number' + invoices.invoiceNumber + 'open link in new tab'" class="dm-link" target="_blank">
                  {{invoices.invoiceNumber}}
                </a>
                <a tabindex="0" (keyup.enter)="getInvoicePDF(invoices.invoiceNumber)" (click)="getInvoicePDF(invoices.invoiceNumber)" *ngIf="invoices.status !== 'Parked'"
                  [attr.aria-label]="'Document number'+ invoices.invoiceNumber + 'open link in new tab'" class="dm-link custom_download"
                  target="_blank">
                  {{invoices.invoiceNumber}}
                </a>
              </td>
              <td class="text-left" *ngIf="!isCurrentUserPartOfTeamStructure">
                {{invoices.invoiceNumber}}
              </td>
              <td>
                {{invoices.documentType | dmDisplayDashIfEmptyOrNull}}
                <span *ngIf="!invoices.documentType" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </td>
              <td class="text-right p-r-27">
                {{invoices.invoiceTotal | dmDisplayCurrencyOrDash:invoices.currencySymbol}}
                <span *ngIf="!invoices.invoiceTotal" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </td>
              <td>
                {{invoices.currencySymbol | dmDisplayDashIfEmptyOrNull}}
                <span *ngIf="!invoices.currencySymbol" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </td>
              <td>
                {{invoices.status | dmDisplayDashIfEmptyOrNull}}
                <span *ngIf="!invoices.status" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </td>
              <td>
                <span>{{invoices.startDate | dmDisplayDateOrDashPipe}}</span>
                <span *ngIf="!invoices.startDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </td>
              <td>
                <span>{{invoices.endDate | dmDisplayDateOrDashPipe}}</span>
                <span *ngIf="!invoices.endDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </td>
              <td>
                <span>{{invoices.paidDate | dmDisplayDateOrDashPipe}}</span>
                <span *ngIf="!invoices.paidDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
              </td>
              <td>{{invoices.timestamp ? (invoices.timestamp | date: 'dd-MMM-yyyy hh:mm a') : '-'}}</td>
              <td class="maxwidth-220">
                <div *ngFor="let proj of invoices.project">
                  <a role="link" tabindex="0" class="dm-link" [uiSref]="RouteName.ProjectSummary" [uiParams]="{projectId: proj.projectId}"
                    [attr.aria-label]="invoices.invoiceNumber + ' Project'+ proj.projectName" (click)="logProjectClick()">
                    {{proj.projectName}}
                  </a>
                </div>
              </td>
              <td>
                <section class="invoice-details-overlay p-r-12">
                  <span class="sr-only">
                    Details
                  </span>
                  <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'left'"
                    [ngbTooltipContent]="dminvoice" [dmIcon]="'dm-tile--more-circle icon icon-moreLegacy'"
                    [ariaLabelToolTip]="invoices.invoiceNumber + ' more Information'" (click)="logMoreInfoClick()">
                  </dm-tooltip>
                  <ng-template #dminvoice>
                    <div id="dminvoice{{invoices.invoiceNumber}}">
                      <section class="invoice-overlay pd-b-10">
                        <p class="font-body">Invoice Details</p>
                      </section>
                      <section class="invoice-overlay--body">
                        <label class="font-label">Approved Date</label>
                        <p class="font-caption-alt pd-b-10">
                          <span
                            *ngIf="invoices.status != 'Parked'">{{invoices.approveDate | dmDisplayDateOrDashPipe}}</span>
                          <span
                            *ngIf="invoices.status == 'Parked'">{{invoices.legalCreatedDate | dmDisplayDateOrDashPipe}}</span>
                        </p>
                        <label class="font-label">Contracted Amount</label>
                        <p class="font-caption-alt pd-b-10">
                          <span>{{invoices.contractAmount |
                                                    dmDisplayCurrencyOrDash:invoices.currencySymbol}}</span>
                        </p>
                        <label class="font-label">Invoice Comments</label>
                        <p class="font-caption-alt pd-b-10">
                          <span>{{invoices.invoiceComments | dmDisplayDashIfEmptyOrNull}}</span>
                        </p>
                      </section>
                    </div>
                  </ng-template>
                  <span class="sr-only">press tab to navigate to tooltip button</span>
                </section>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </section>
  </section>
  <dm-service-error [ngbTooltipContent]="errorText" *ngIf="isServerError"></dm-service-error>
  <dm-no-data [noDataText]="noInvoicesText" showImage="true" *ngIf="!isComponentLoading && !showCaption && (!invoiceList || (invoiceList && !invoiceList.length))"></dm-no-data>
</dm-tile>

<!-- ----------------------------------------------------------------------------------------------------- -->

<section class="dm-invoice-table-data" *ngIf="deviceFactory.isSmallScreen()">
  <dm-loading *ngIf="isComponentLoading" [isCustomText]="true" [loadingText]="loadingText" [showLoading]="showLoading"
    [errorText]="errorText"></dm-loading>
  <ng-container *ngIf="!isComponentLoading">
    <section id="noinvoices" class="noinvoices-tile"
      [ngClass]="{'noinvoicesActive':(invoiceList && invoiceList.length===0)}">
      <dm-no-data [noDataText]="noInvoicesText"></dm-no-data>
    </section>
    <section id="dminvoicetablet" check-record-empty>
      <!-- <section [ngClass]="{'pull-left': deviceFactory.isMobile()}">
            <section class="dm-tile--p-b-12" id="invoice_itemback">
                <a href class="dm-link dm-link--small" id="invoice_itemback-link">
                    <span class="icon icon-chevron-left"></span>
                    <span>Invoices</span>
                </a>
            </section>
        </section> -->

      <section class="col-sm-12 col-xs-12 pad0 dm-invoices-tablet-mobile">
        <section class="dminvoicebody"
          *ngFor="let invoices of invoiceList
                                | filter: (selectedStatus ? {status: selectedStatus} : selectedStatus)
                                | filter: (invoiceNumber? {invoiceNumber: invoiceNumber}: invoiceNumber)
                                | orderBy: sortVal: sortAscy
                                | slice:0: (limitInvoices !== -1 ? limitInvoices : invoiceList.length); let index = index">

          <!-- ----------------------------------------------------------------------------------------------------- -->
          <section *ngIf="deviceFactory.isTablet()">
            <section class="col-xs-12 col-sm-12 dm-tile--p-b-12 pad0">
              <section class="invoice-title invoice-width-16 dm-tile--p-r-6">
                <section class="invoice-h-box">
                  <h6 class="font-label" (click)="logDocListItemClick()">Document Number</h6>
                  <a id="invoices_{{index}}" *ngIf="invoices.status === 'Parked'" [href]="invoices.invoiceNumberLink"
                    target="_blank" class="font-caption-alt pull-right dm-link" aria-label="open link in new tab">
                    {{invoices.invoiceNumber | dmDisplayDashIfEmptyOrNull}}
                  </a>
                  <a href (click)="getInvoicePDF(invoices.invoiceNumber)" *ngIf="invoices.status !== 'Parked'"
                    target="_blank" class="font-caption-alt pull-right dm-link"
                    [attr.aria-label]="'Document number'+ invoices.invoiceNumber + 'open link in new tab'">
                    {{invoices.invoiceNumber}}
                  </a>
                </section>
              </section>
              <section class="invoice-title invoice-width-16 dm-tile--p-l-r-6">
                <h6 class="font-label">Document type</h6>
                <p class="font-caption-alt">
                  <span
                    [attr.aria-label]="invoices.documentType ? invoices.documentType : 'Document type not avaiable'">
                    {{invoices.documentType | dmDisplayDashIfEmptyOrNull}} </span>
                </p>
              </section>
              <section class="invoice-title invoice-width-16 dm-tile--p-l-r-6">
                <h6 class="font-label">Period Start</h6>
                <p class="font-caption-alt">
                  <span
                    [attr.aria-label]="invoices.startDate ? (invoices.startDate | date : 'dd-MMM-yyyy') : 'Period Start not avaiable'">
                    {{invoices.startDate | dmDisplayDateOrDashPipe}}
                  </span>
                </p>
              </section>
              <section class="invoice-title invoice-width-16 dm-tile--p-l-r-6">
                <h6 class="font-label">Period End</h6>
                <p class="font-caption-alt">
                  <span
                    [attr.aria-label]="invoices.endDate ? (invoices.endDate | date : 'dd-MMM-yyyy') : 'Period End not avaiable'">
                    {{invoices.endDate | dmDisplayDateOrDashPipe}}
                  </span>
                </p>
              </section>
              <section class="invoice-title invoice-width-16 dm-tile--p-l-r-6">
                <h6 class="font-label">Paid Date</h6>
                <p class="font-caption-alt">
                  <span
                    [attr.aria-label]="invoices.paidDate ? (invoices.paidDate | date : 'dd-MMM-yyyy') : 'Paid Date not available'">
                    {{invoices.paidDate | dmDisplayDateOrDashPipe}}
                  </span>
                </p>
              </section>
              <section class="invoice-title invoice-width-16 dm-tile--p-l-6">
                <h6 class="font-label">Approved Date</h6>
                <p class="font-caption-alt">
                  <span
                    [attr.aria-label]="invoices.approveDate ? (invoices.approveDate | date : 'dd-MMM-yyyy') : 'Approved Date not available'">
                    {{invoices.approveDate | dmDisplayDateOrDashPipe}}
                  </span>
                </p>
              </section>
            </section>
            <section class="col-sm-12 col-xs-12 b-t-15 dm-tile--p-t-b-12 pad0">
              <section class="invoice-title invoice-width-40 dm-tile--p-r-6">
                <h6 class="font-label">Project</h6>
                <a class="dm-link font-caption-alt" [uiSref]="RouteName.ProjectSummary" [uiParams]="{projectId: proj.projectId}"
                  *ngFor="let proj of invoices.project"
                  (click)="logProjectClick()" attr.aria-label="Project {{proj.projectName}}">
                  <span [innerHtml]="proj.projectName"></span>
                  <br>
                </a>
              </section>
              <section class="invoice-title invoice-width-10 dm-tile--p-l-r-6">
                <h6 class="font-label">Currency</h6>
                <p class="font-caption-alt"> {{invoices.currencySymbol | dmDisplayDashIfEmptyOrNull}}
                </p>
              </section>
              <section class="invoice-title invoice-width-14 dm-tile--p-l-r-6">
                <h6 class="font-label">Status</h6>
                <p class="font-caption-alt"> {{invoices.status | dmDisplayDashIfEmptyOrNull}} </p>
              </section>
              <section class="invoice-title invoice-width-18 text-right dm-tile--p-l-r-6">
                <h6 class="font-label">
                  <span class="m-r--16">Invoice Total</span>
                  <dm-tooltip role="tooltip" class="dm-tooltipinline projectToolTip invoice-tooltip"
                    [dmPlacement]="'bottom-right'" [ariaLabelToolTip]="'Invoice Total'"
                    [ngbTooltipContent]="'Invoiced amount is the total amount of the invoice. This may include the total amount of all projects on the invoice, and may not match total amount invoiced for a specific project'"
                    [dmIcon]="'tile__icon--info icon icon-info'">
                  </dm-tooltip>
                  <span class="sr-only">press tab to navigate to tooltip button</span>
                </h6>
                <p class="font-caption-alt text-right p-r-12"> {{invoices.invoiceTotal |
                                dmDisplayCurrencyOrDash:invoices.currencySymbol}} </p>
              </section>
              <section class="invoice-title invoice-width-18 text-right dm-tile--p-l-6">
                <h6 class="font-label">Contracted Amount</h6>
                <p class="font-caption-alt"> {{invoices.contractAmount |
                                dmDisplayCurrencyOrDash:invoices.currencySymbol}} </p>
              </section>
            </section>
            <section class="col-sm-12 col-xs-12 pad0">
              <section class="invoice-title invoice-width-40 dm-tile--p-r-6">
                <h6 class="font-label">Timestamp (PST)</h6>
                <p class="font-caption-alt">
                  <span
                    [attr.aria-label]="invoices.timestamp ? (invoices.timestamp | date: 'dd-MMM-yyyy hh:mm a') : 'Timestamp not available'">
                    {{invoices.timestamp ? (invoices.timestamp | date: 'dd-MMM-yyyy hh:mm a') : '-'}}
                  </span>
                </p>
              </section>
              <section class="invoice-title invoice-width-60 dm-tile--p-l-6">
                <h6 class="font-label">Invoice Comments</h6>
                <p class="font-caption-alt">
                  <!-- <span [innerHtml]="invoices.invoiceComments ?  invoices.invoiceComments | slice:1:51 : '-'"></span> -->
                  <span> {{invoices.invoiceComments | dmDisplayDashIfEmptyOrNull}} </span>
                  <span *ngIf="invoices && invoices.invoiceComments && invoices.invoiceComments.length>50">...</span>
                </p>
                <p class="font-caption-alt">{{invoices.invoiceComments}}</p>
                <button class="showMoreText"
                  *ngIf="invoices && invoices.invoiceComments && invoices.invoiceComments.length>50"></button>
              </section>
            </section>
          </section>


          <!-- ----------------------------------------------------------------------------------------------------- -->
          <section *ngIf="deviceFactory.isMobile()">
            <section class="col-xs-12 pad0">
              <section class="col-xs-12 pad0 dm-tile--p-b-12">
                <section class="col-xs-6 pad0">
                  <section class="invoice-h-box">
                    <h6 class="font-label">Document Number</h6>
                    <a id="invoices_{{index}}" [href]="invoices.invoiceNumberLink" target="_blank" aria-label="open link in new tab"
                      class="font-caption-alt pull-right dm-link" (click)="logDocListItemClick()">
                      {{invoices.invoiceNumber | dmDisplayDashIfEmptyOrNull}}
                    </a>
                  </section>
                </section>
                <section class="col-xs-6 pad0">
                  <h6 class="font-label">Document Type</h6>
                  <p class="font-caption-alt">
                    <span
                      [attr.aria-label]="invoices.documentType ? invoices.documentType : 'Document Type not available'">
                      {{invoices.documentType | dmDisplayDashIfEmptyOrNull}} </span>
                  </p>
                </section>
              </section>
              <section class="col-xs-12 pad0 dm-tile--p-b-12">
                <section class="col-xs-6 pad0">
                  <h6 class="font-label">Period Start</h6>
                  <p class="font-caption-alt">
                    <span
                      [attr.aria-label]="invoices.startDate ? (invoices.startDate | date : 'dd-MMM-yyyy') : 'Period Start not available'">
                      {{invoices.startDate | dmDisplayDateOrDashPipe}}
                    </span>
                  </p>
                </section>
                <section class="col-xs-6 pad0">
                  <h6 class="font-label">Period End</h6>
                  <p class="font-caption-alt">
                    <span
                      [attr.aria-label]="invoices.endDate ? (invoices.endDate | date : 'dd-MMM-yyyy') : 'Period End not available'">
                      {{invoices.endDate | dmDisplayDateOrDashPipe}}
                    </span>
                  </p>
                </section>
              </section>
              <section class="col-xs-12 pad0 dm-tile--p-b-12">
                <section class="col-xs-6 pad0">
                  <h6 class="font-label">Paid Date</h6>
                  <p class="font-caption-alt">
                    <span
                      [attr.aria-label]="invoices.paidDate ? (invoices.paidDate | date : 'dd-MMM-yyyy') : 'paid date not available'">
                      {{invoices.paidDate | dmDisplayDateOrDashPipe}}
                    </span>
                  </p>
                </section>
                <section class="col-xs-6 pad0">
                  <h6 class="font-label">Approved Date</h6>
                  <p class="font-caption-alt">
                    <span
                      [attr.aria-label]="invoices.approveDate ? (invoices.approveDate | date : 'dd-MMM-yyyy') : 'approved date not available'">
                      {{invoices.approveDate | dmDisplayDateOrDashPipe}}
                    </span>
                  </p>
                </section>
              </section>
              <section class="col-xs-12 pad0 dm-tile--p-b-12">
                <section class="col-xs-6 pad0">
                  <h6 class="font-label">Currency</h6>
                  <p class="font-caption-alt">
                    {{invoices.currencySymbol | dmDisplayDashIfEmptyOrNull}} </p>
                </section>
                <section class="col-xs-6 pad0">
                  <h6 class="font-label">Status</h6>
                  <p class="font-caption-alt"> {{invoices.status | dmDisplayDashIfEmptyOrNull}} </p>
                </section>
              </section>
            </section>
            <section class="col-xs-12 pad0 b-t-15 dm-tile--p-t-b-12">
              <section class="col-xs-6 pad0 text-right">
                <h6 class="font-label">
                  Invoice Total
                  <dm-tooltip role="tooltip" class="dm-tooltipinline projectToolTip" [dmPlacement]="'bottom'"
                    [ngbTooltipContent]="'Invoiced amount is the total amount of the invoice. This may include the total amount of all projects on the invoice, and may not match total amount invoiced for a specific project'"
                    [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Invoice Total Information">
                  </dm-tooltip>
                  <span class="sr-only">press tab to navigate to tooltip button</span>
                </h6>
                <p class="font-caption-alt p-r-12"> {{invoices.invoiceTotal |
                                dmDisplayCurrencyOrDash:invoices.currencySymbol}} </p>
              </section>
              <section class="col-xs-6 pad0 text-right">
                <h6 class="font-label">Contracted Amount</h6>
                <p class="font-caption-alt"> {{invoices.contractAmount |
                                dmDisplayCurrencyOrDash:invoices.currencySymbol}} </p>
                            </section>
                        </section>
                        <section class="col-xs-12 pad0 b-t-15 dm-tile--p-t-12">
                            <section class="col-xs-12 pad0 dm-tile--p-b-12">
                                <h6 class="font-label">Project</h6>
                                <a class="dm-link font-caption-alt" [uiSref]="RouteName.ProjectSummary" [uiParams]="{projectId: proj.projectId}"
                                    *ngFor="let proj of invoices.project"
                                    [attr.aria-label]="'Project' + proj.projectName" (click)="logProjectClick()">
                                    <span>
                                        {{proj.projectName | dmDisplayDashIfEmptyOrNull}}
                                    </span>
                                    <br>
                                </a>
                            </section>
                            <section class="col-xs-12 pad0 dm-tile--p-b-12">
                                <h6 class="font-label">Timestamp (PST)</h6>
                                <p class="font-caption-alt">
                                    <span
                                        [attr.aria-label]="invoices.timestamp ? (invoices.timestamp | date: 'dd-MMM-yyyy hh:mm a') : 'timestamp not avaiable'">
                                        {{invoices.timestamp ? (invoices.timestamp | date: 'dd-MMM-yyyy hh:mm a') : '-'}}
                                    </span>
                                </p>
                            </section>
                            <section class="col-xs-12 pad0">
                                <h6 class="font-label">Invoice Comments</h6>
                                <p class="font-caption-alt">
                                    <!-- <span [innerHtml]="invoices.invoiceComments ? invoices.invoiceComments | slice:1:51 : '-'"></span> -->
                                    <span> {{invoices.invoiceComments | dmDisplayDashIfEmptyOrNull}} </span>
                                    <span
                                        *ngIf="invoices && invoices.invoiceComments && invoices.invoiceComments.length>50">...</span>
                                </p>
                                <p class="font-caption-alt">{{invoices.invoiceComments}}</p>
                                <button class="showMoreText"
                                    *ngIf="invoices && invoices.invoiceComments && invoices.invoiceComments.length>50">
                                </button>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </ng-container>
</section>