<section class="staffing-requests-grid--wrapper">
    <dm-loading [loadingText]="'Planned Requests'" [errorText]="'Unable to load Planned Requests'"
        [showLoading]="showLoading" *ngIf="isLoading"></dm-loading>
    <section class="row" *ngIf="!isLoading">
        <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12"  [ngClass]="{'dm-overflow': deviceFactory.isSmallScreen()}">
            <div class="sr-only" id="typeOfRequestsCaption">{{typeOfRequests}}</div>
            <table class="staffing-requests-grid" width="100%" role="grid"
                aria-labelledby="typeOfRequestsCaption">
                <thead class="staffing-requests-grid--header sticky-staffing-requests-grid-header"
                    [ngStyle]="{'top': setTopPositionForStickyHeaderOfRequestGrid()}" *ngIf="rolesRequests.length > 0"
                    [ngClass]="{'planned-requests': typeOfRequests === 'Planned Requests'}">
                    <tr>
                        <th scope="col" [ngClass]="(typeOfRequests === 'Planned Requests') ? 'width-31' : 'width-23'">
                            <button type="button" id="toggleAllDemands" class="acc-btn staffing-requests-grid--btns"
                                [ngClass]="{'pad-3': !allDemandsExpanded}"
                                [title]="allDemandsExpanded ? 'Expand Grid' : 'Collapse Grid'"
                                [attr.aria-expanded]="!allDemandsExpanded"
                                (click)="expandCollapseAllDemands(allDemandsExpanded)">
                                <span class="icon checkmark-left"
                                    [ngClass]="allDemandsExpanded ? 'icon-AddLegacy' : 'icon-DashKey'"></span>
                            </button>
                            <span class="role-text-right" style="width: auto;">Role/ <br />Resource</span>
                        </th>
                        <th scope="col" class="width-8" *ngIf="typeOfRequests === 'Unplanned Requests'">
                            <span class="dis-block">Primary</span>
                            <span class="dis-block">Skill</span>
                        </th>
                        <th scope="col" class="width-6" *ngIf="isInternalEngagement === false">
                            <span class="dis-block">Bill Rate</span>
                            <span class="dis-block">
                                ({{wbsCurrency}}/hr)
                                <dm-tooltip role="tooltip" class="dm-tooltipinline amedmentsTooltip-mobile"
                                    [dmPlacement]="'bottom'"
                                    [ngbTooltipContent]="'The negotiated rate the customer has agreed to pay for the specified role'"
                                    [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="Bill rate Information">
                                </dm-tooltip>
                            </span>
                            <span class="sr-only">press tab to navigate to tooltip button</span>
                        </th>
                        <th scope="col" class="width-6">
                            <span class="dis-block">Cost Rate</span>
                            <span class="dis-block">
                                ({{wbsCurrency}}/hr)
                                <dm-tooltip role="tooltip" class="dm-tooltipinline amedmentsTooltip-mobile"
                                    [dmPlacement]="'bottom'"
                                    [ngbTooltipContent]="'Cost per hour based on the role and delivery location.'"
                                    [dmIcon]="'tile__icon--info icon icon-info'"
                                    ariaLabelToolTip="Cost rate Information">
                                </dm-tooltip>
                            </span>
                            <span class="sr-only">press tab to navigate to tooltip button</span>
                        </th>
                        <th scope="col" class="width-6">
                            <span class="dis-block">Planned</span>
                            <span class="dis-block">Labor (hrs)</span>
                        </th>
                        <th scope="col" class="width-6">
                            <span class="dis-block">Staffed</span>
                            <span class="dis-block">Labor (hrs)</span>
                        </th>
                        <th scope="col" class="width-6">
                            <span class="dis-block">Planned</span>
                            <span class="dis-block">Cost ({{wbsCurrency}})</span>
                        </th>
                        <th scope="col" class="width-7">
                            <span class="dis-block">Staffed</span>
                            <span class="dis-block">
                                Cost ({{wbsCurrency}})
                                <dm-tooltip role="tooltip" class="dm-tooltipinline amedmentsTooltip-mobile"
                                    [dmPlacement]="'bottom'" [ngbTooltipContent]="staffedCostToolTip"
                                    [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Staffed Information">
                                </dm-tooltip>
                            </span>
                            <span class="sr-only">press tab to navigate to tooltip button</span>
                        </th>
                        <th scope="col" class="width-9">
                            Duration
                        </th>
                        <th scope="col" class="width-6" *ngIf="typeOfRequests === 'Planned Requests'">
                            <span class="dis-block">Cost</span>
                            <span class="dis-block">Variation</span>
                        </th>
                        <th scope="col" class="width-8">
                            Status
                            <dm-tooltip role="tooltip" class="dm-tooltipinline amedmentsTooltip-mobile"
                                [dmPlacement]="'bottom'" [ngbTooltipContent]="'The status of request in GRM'"
                                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Status Information">
                            </dm-tooltip>
                            <span class="sr-only">press tab to navigate to tooltip button</span>
                        </th>
                        <th scope="col" class="width-9 dm-p-l-imp-20">
                            Actions
                        </th>
                    </tr>
                </thead>
            <tbody>
            <div class="staffing-requests-grid" width="100%"
                *ngIf="typeOfRequests === 'Unplanned Requests'">
                <div class="staffing-requests-grid--unplanned font-caption-alt">
                    <tr *ngFor="let item of rolesRequests" role="row">
                        <th scope="row" class="align-top">
                            <section class="staffing-requests-grid--unplanned--role-wrapper">
                                <div class="staffing-requests-grid--unplanned--checkbox">
                                    <fieldset>
                                        <div class="input__checkbox">
                                            <input type="checkbox" class="input__checkbox--focus" value="None"
                                                [attr.aria-label]="'Select Resource'+ item.resourceID"
                                                [id]="item.resourceID" [name]="item.resourceID">
                                            <label [for]="item.resourceID"></label>
                                        </div>
                                    </fieldset>
                                </div>
                                <section class="staffing-requests-grid--unplanned--user-photo-wrapper">
                                    <section class="userPhoto userPhoto--dashed-border" *ngIf="!item.resourcePhoto">
                                        <span class="icon icon-user icon--big"></span>
                                    </section>
                                    <section class="userPhoto" *ngIf="item.resourcePhoto">
                                        <img [src]="item.resourcePhoto" alt="item.role">
                                    </section>
                                </section>
                                <section class="staffing-requests-grid--unplanned--role-details">
                                    <p class="semi-bold" [innerHTML]="item.role | highlight : searchText"></p>
                                    <p>
                                        Resource ID:
                                        <span [innerHTML]="item.resourceID | highlight : searchText"></span>
                                    </p>
                                </section>
                            </section>
                        </th>
                        <td class="align-top">{{item.primarySkill}}</td>
                        <td class="align-top">{{item.billRate ? (item.billRate | number: '1.2-2'): '0.00'}}</td>
                        <td class="align-top">{{item.costRate ? (item.costRate | number: '1.2-2'): '0'}}</td>
                        <td class="align-top">{{item.plannedLabor ? item.plannedLabor : '0'}}</td>
                        <td class="align-top">{{item.staffedLabor ? item.staffedLabor : '0'}}</td>
                        <td class="align-top">{{item.plannedCost ? (item.plannedCost | number: '1.2-2'): '0.00'}}</td>
                        <td class="align-top">{{item.staffedCost ? (item.staffedCost | number: '1.2-2'): '0.00'}}</td>
                        <td class="align-top">
                            <span class="no-wrap">
                                <span>{{item.startDate | dmDisplayDateOrDashPipe}}</span>
                                -
                            </span>
                            <span class="dis-block">{{item.endDate | dmDisplayDateOrDashPipe}}</span>
                        </td>
                        <td class="align-top" [ngClass]="{'font-red':item.status === 'Draft'}">
                            {{item.status}}</td>
                        <td class="align-top">
                            <section class="action-btns">
                                <a href="#" class="pull-left" *ngIf="item.status !== 'Proposed'">
                                    <span class="icon icon-editLegacy"></span>
                                </a>
                                <section class="pull-left" *ngIf="item.status === 'Proposed'">
                                    <section>
                                        <button type="button" title="Accept">
                                            <span class="icon icon-CheckMark"></span>
                                        </button>
                                    </section>
                                    <section>
                                        <button type="button" title="Reject">
                                            <span class="icon icon-rejectLegacy"></span>
                                        </button>
                                    </section>
                                </section>
                            </section>
                        </td>
                    </tr>
                </div>
            </div>            
            <div id="demandRoleCaption" class="sr-only">Demand Role</div>
            <div class="scrollable-section" *ngIf="typeOfRequests === 'Planned Requests' && !showNoDemandsMessage" role="none">
                <div class="staffing-requests-grid planned-requests-content requests-grid-auto-width" [ngClass]="{'requests-grid-auto-width-internal': isInternalEngagement}"
                    *ngFor="let item of rolesViewModelRequests | filter : (selectedProjectId ? {taskProjectId: selectedProjectId} : undefined)">
                    <div
                        class="staffing-requests-grid--caption-project font-caption-alt request-border-high-contrast pad-12">
                        <tr class="row no-mar" role="row">
                            <div class="col-md-7 col-sm-7 col-lg-7 col-ff dm-p-l-imp-0" role="gridcell">
                                <button type="button" class="acc-btn" [attr.aria-expanded]="item.isTasksExpanded"
                                    title="{{'Project Id EBS: '+ item.taskProjectId}}" (click)="gridExpandIcon(item)">
                                    <span class="icon"
                                        [ngClass]="item.isTasksExpanded ? 'icon-ChevronUp' : 'icon-ChevronDown'"></span>
                                </button>
                                <span>
                                    Project:
                                    <span>{{item.taskProjectName}}</span>
                                </span>
                                <span class="p-l-12">
                                    EBS:
                                    <span>{{item.taskProjectId}}</span>
                                </span>
                            </div>
                            <div class="col-md-5 col-sm-5 col-lg-5 text-right p-t-5 col-ff no-pad-right" role="gridcell">
                                <span class="p-l-r-12">
                                    Start Date:
                                    <span>{{item.projectStartDate | dmDisplayDateOrDashPipe}}</span>
                                </span>
                                <span class="p-l-r-12">
                                    End Date:
                                    <span>{{item.projectEndDate | dmDisplayDateOrDashPipe}}</span>
                                </span>
                            </div>
                        </tr>
                    </div>
                    <ng-container *ngIf="item.isTasksExpanded">
                        <dm-loading [loadingText]="'Resource Requests'" [errorText]="'Unable to load Resource Requests'"
                            [showLoading]="true"
                            *ngIf="(!item.taskModelDetails || (item.taskModelDetails && !item.taskModelDetails.length)) && item.showLoading">
                        </dm-loading>
                        <div
                            *ngFor="let entry of item.taskModelDetails | filter : (selectedServiceId ? {taskServiceId: selectedServiceId} : undefined) | filter : (selectedTaskId ? {taskId: selectedTaskId} : undefined)">
                            <div
                                class="staffing-requests-grid--caption font-caption-alt request-border-high-contrast">
                                <div colspan="12" class="pad-12">
                                    <tr class="row" role="row">
                                        <div class="col-md-7 col-sm-7 col-lg-7" role="gridcell">
                                            <button type="button" class="acc-btn"
                                                [attr.aria-expanded]="entry.isDemandsExpanded"
                                                [attr.aria-label]="entry.isDemandsExpanded ? 'expanded' : 'collapsed'"
                                                title="{{'Service Id EBS: '+ entry.taskId}}"
                                                id="{{ 'serviceId' + entry.taskId }}"
                                                (click)="entry.isDemandsExpanded = !entry.isDemandsExpanded">
                                                <span class="icon"
                                                    [ngClass]="entry.isDemandsExpanded ? 'icon-ChevronUp' : 'icon-ChevronDown'"></span>
                                            </button>
                                            <strong class="col-2a">
                                                EBS:
                                                <span>{{entry.taskId}}</span>
                                            </strong>
                                            /
                                            <span [title]="entry.taskProjectName"
                                                class="no-wrap-text">{{entry.taskProjectName}}</span>
                                            /
                                            <span [title]="entry.taskServiceName"
                                                class="no-wrap-text">{{entry.taskServiceName}}</span>
                                            /
                                            <span [title]="entry.taskName"
                                                class="no-wrap-text">{{entry.taskName}}</span>
                                        </div>
                                        <div class="col-md-5 col-sm-5 col-lg-5 text-right p-t-7 no-pad-right" role="gridcell">
                                            <span class="p-l-r-12">
                                                Start Date:
                                                <span>{{entry.projectStartDate | dmDisplayDateOrDashPipe}}</span>
                                            </span>
                                            <span class="p-l-r-12">
                                                End Date:
                                                <span>{{entry.projectEnddate | dmDisplayDateOrDashPipe}}</span>
                                            </span>
                                        </div>
                                    </tr>
                                </div>
                            </div>
                            <ng-container *ngIf="item.isTasksExpanded && entry.isDemandsExpanded">
                                <div colspan="12" class="p-6-0">
                                        <div class="font-title no-resource-text pad-12 m-b-0" role="row"
                                            *ngIf="!entry.demands || (entry && entry.demands.length === 0)">
                                            <p *ngIf="!showMessageForNonTeamMembers && entry.taskTypeCode !== riskReserveTypeCode"
                                                class="font-subtitle dm-tile--noRecords" role="gridcell">
                                                No Resource Requests have been synced or created for this Role. Please
                                                <a *ngIf="isInternalEngagement || showUSPubSecRMActions" [href]="'#/Delivery/compassPackage/' + entry.taskCompassPackageId +'/sapPackage/' +entry.taskProjectId"
                                                    (click)="logCreateClick()">
                                                    click here
                                                </a>
                                                <a  *ngIf="!isInternalEngagement && !showUSPubSecRMActions" 
                                                    [href]="'#/workbench/Requestor?SapProjectId=' + entry.taskProjectId +'&C1PackageId=' +entry.taskCompassPackageId">
                                                    click here
                                                </a>
                                                to create requests.
                                            </p>
                                            <p *ngIf="!showMessageForNonTeamMembers && entry.taskTypeCode === riskReserveTypeCode"
                                                class="font-subtitle dm-tile--noRecords" role="gridcell">
                                                No Demand has been created against Risk Reserve task. Please navigate to
                                                <a (click)="openCurrentFinancialPlan()" class="view-all-amt-btn"
                                                    href="javascript:void(0)">
                                                    Current Financial Plan
                                                </a>
                                                to create a demand.
                                            </p>
                                            <p *ngIf="showMessageForNonTeamMembers"
                                                class="font-subtitle dm-tile--noRecords" role="gridcell">
                                                You cannot view Staffing information as you are not part of Project
                                                team.
                                            </p>
                                        </div>
                                        <div *ngFor="let request of entry.demands"
                                            [ngClass]="{'demands_table__wrapper' : request.isRolesExpanded}">
                                            <div
                                                [ngClass]="{'demands_table__inner-content' : request.isRolesExpanded}">
                                                <div
                                                    class="staffing-requests-grid--unplanned planned-requests font-caption-alt"
                                                    [ngClass]="{'expanded-demands' : request.isRolesExpanded}"
                                                    width="100%">
                                                        <tr class="request-border-top-high-contrast request-border" role="row">
                                                            <th scope="row" class="width-31 align-top p-l-40" role="gridcell"
                                                                [ngClass]="{'proposed-border' : request.status === 'Proposed'}">
                                                                <span class="sr-only" scope="col">Role/
                                                                    <br />Resource</span>
                                                                <section
                                                                    class="staffing-requests-grid--unplanned--role-wrapper">
                                                                    <section
                                                                        class="staffing-requests-grid--unplanned--checkbox">
                                                                        <button type="button" class="acc-btn"
                                                                            id="{{ 'demandId' + request.demandId }}"
                                                                            [attr.aria-expanded]="request.isRolesExpanded"
                                                                            title="{{'Demand Id '+ request.demandId}}"
                                                                            [attr.aria-label]="request.demandRole + request.resources.length + '. Demand Id '+ request.demandId"
                                                                            (click)="request.isRolesExpanded = !request.isRolesExpanded">
                                                                            <span class="icon"
                                                                                [ngClass]="request.isRolesExpanded ? 'icon-ChevronUp' : 'icon-ChevronDown'"></span>
                                                                        </button>
                                                                    </section>
                                                                    <section
                                                                        class="staffing-requests-grid--unplanned--role-details" role="grid">
                                                                        <p class="semi-bold">
                                                                            <span
                                                                                [innerHTML]="request.demandRole | highlight : searchText"></span>
                                                                            (
                                                                            <span
                                                                                [innerHTML]="request.resources.length | highlight : searchText"></span>
                                                                            )
                                                                        </p>
                                                                        <p>
                                                                            Demand ID:
                                                                            <span
                                                                                [innerHTML]="request.demandId | highlight : searchText"></span>
                                                                            <dm-tooltip role="tooltip"
                                                                                class="dm-tooltipinline"
                                                                                [dmPlacement]="'right'"
                                                                                [ngbTooltipContent]="demandRolesTooltip"
                                                                                [dmIcon]="'tile__icon--info icon icon-info'"
                                                                                ariaLabelToolTip="Demand Id Information">
                                                                            </dm-tooltip>
                                                                            <ng-template #demandRolesTooltip>
                                                                                <div class="row m-b-12">
                                                                                    <div
                                                                                        class="col-md-6 col-sm-6 col-lg-6">
                                                                                        <h5 class="font-caption m-b-0">
                                                                                            Demand ID
                                                                                        </h5>
                                                                                        <p class="font-caption-alt"
                                                                                            [innerHTML]="request.demandId | highlight : searchText">
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div
                                                                                        class="col-md-6 col-sm-6 col-lg-6">
                                                                                        <h5 class="font-caption m-b-0">
                                                                                            WBS L0 ID
                                                                                        </h5>
                                                                                        <p class="font-caption-alt"
                                                                                            [innerHTML]="request.engagementId | highlight : searchText">
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-md-6 col-sm-6 col-lg-6">
                                                                                        <h5 class="font-caption m-b-0">
                                                                                            WBS L0 Description
                                                                                        </h5>
                                                                                        <p class="font-caption-alt"
                                                                                            [innerHTML]="item.taskEngagementName | highlight : searchText">
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </ng-template>
                                                                            <span class="sr-only">press tab to navigate to tooltip button</span>
                                                                        </p>
                                                                    </section>
                                                                </section>
                                                            </th>
                                                            <td class="width-6" *ngIf="isInternalEngagement === false" role="gridcell">
                                                                <span class="sr-only">Bill rate (USD/hr)</span>
                                                                <span
                                                                    [innerHTML]="(request.demandBillRate
                                                                            ? (request.demandBillRate | number: '1.2-2') : '0.00')| highlight: searchText">
                                                                </span>
                                                            </td>
                                                            <td class="width-6" role="gridcell">
                                                                <span class="sr-only">Cost rate (USD/hr)</span>
                                                                <span
                                                                    [innerHTML]="(request.demandCostRate
                                                                            ? (request.demandCostRate | number: '1.2-2') : '0.00')| highlight: searchText">
                                                                </span>
                                                            </td>
                                                            <td class="width-6" role="gridcell">
                                                                <span class="sr-only">Planned(CFP) labor (hrs)</span>
                                                                <span
                                                                    [innerHTML]="(request.demandPlannedQuantity
                                                                            ? request.demandPlannedQuantity : '0')| highlight: searchText"></span>
                                                            </td>
                                                            <td class="width-6" role="gridcell">
                                                                <span class="sr-only">Staffed(GRM) labor (hrs)</span>
                                                                <span
                                                                    [innerHTML]="(request.demandStaffedQuantity
                                                                            ? request.demandStaffedQuantity : '0')| highlight: searchText"></span>
                                                            </td>
                                                            <td class="width-6" role="gridcell">
                                                                <span class="sr-only">Planned cost (USD)</span>
                                                                <span
                                                                    [innerHTML]="(request.demandPlannedCost
                                                                            ? (request.demandPlannedCost | number: '1.2-2') : '0.00')| highlight: searchText">
                                                                </span>
                                                            </td>
                                                            <td class="width-7" role="gridcell">
                                                                <span class="sr-only">Staffed cost (USD)</span>
                                                                <span
                                                                    [innerHTML]="((request.demandStaffedCost
                                                                            && request.demandStaffedCost != '-') ?
                                                                            (request.demandStaffedCost | number: '1.2-2') : '0.00')| highlight: searchText">
                                                                </span>
                                                            </td>
                                                            <td class="width-9" role="gridcell">
                                                                <span class="sr-only">Duration</span>
                                                                <span>
                                                                    <span class="no-wrap">
                                                                        <span
                                                                            [innerHTML]="item.projectStartDate | dmDisplayDateOrDashPipe | highlight : searchText"></span>
                                                                        -
                                                                    </span>
                                                                    <span class="dis-block"
                                                                        [innerHTML]="item.projectEndDate | dmDisplayDateOrDashPipe | highlight : searchText"></span>
                                                                </span>
                                                            </td>
                                                            <td class="width-6" role="gridcell">
                                                                <span class="sr-only">Cost variation</span>
                                                                <span>
                                                                    <span
                                                                        [innerHTML]="request.demandCostVariance + '%' | highlight : searchText"></span>
                                                                </span>
                                                            </td>
                                                            <td class="width-8" role="gridcell">
                                                                <span class="sr-only">Status</span>
                                                                <span
                                                                    [ngClass]="{'font-red':request.demandStatus === 'Draft'}"
                                                                    [innerHTML]="getDemandStatus(request) | highlight : searchText"></span>
                                                            </td>
                                                            <td class="width-9" role="gridcell">
                                                                <span class="sr-only">Actions</span>
                                                                <span class="sr-only" *ngIf="request.demandStatus !== 'Proposed'">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                                <span>
                                                                    <section class="action-btns">
                                                                        <section class="pull-left"
                                                                            *ngIf="request.demandStatus === 'Proposed'">
                                                                            <section>
                                                                                <button type="button"
                                                                                    title="Accept Demand">
                                                                                    <span
                                                                                        class="icon icon-CheckMark"></span>
                                                                                </button>
                                                                            </section>
                                                                            <section>
                                                                                <button type="button"
                                                                                    title="Reject Demand">
                                                                                    <span
                                                                                        class="icon icon-rejectLegacy"></span>
                                                                                </button>
                                                                            </section>
                                                                        </section>
                                                                    </section>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <ng-container *ngIf="request.isRolesExpanded">
                                                            <tr *ngIf="!request.resources || (request && !request.resources.length)" role="row">
                                                                <td colspan="12" role="gridcell">
                                                                    <div class="font-title text-center pad-12 m-b-0">
                                                                        <p *ngIf="!showMessageForNonTeamMembers && !isInternalEngagement"
                                                                            class="font-subtitle dm-tile--noRecords">
                                                                            No Resource Requests have been synced or
                                                                            created this Role. Please
                                                                            <a
                                                                                [href]="'#/Delivery/compassPackage/' + entry.taskCompassPackageId +'/sapPackage/' +entry.taskProjectId">
                                                                                click here
                                                                            </a>
                                                                            to create requests.
                                                                        </p>
                                                                        <p *ngIf="!showMessageForNonTeamMembers && isInternalEngagement"
                                                                            class="font-subtitle dm-tile--noRecords">
                                                                            No Resource Requests have been synced or
                                                                            created this Role.
                                                                        </p>
                                                                        <p *ngIf="showMessageForNonTeamMembers"
                                                                            class="font-subtitle dm-tile--noRecords">
                                                                            You cannot view Staffing information as you
                                                                            are not part of Project team.
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr *ngFor="let role of request.resources" class="request-border"
                                                                [ngClass]="{'selected-row' : selectRole.checked }" role="row">
                                                                <th scope="row" class="width-31 align-top p-l-50" role="gridcell"
                                                                    [ngClass]="{'proposed-border' : role.resourceStatus === 'Proposed'}">
                                                                    <span class="sr-only" scope="col">Role/
                                                                        <br />Resource</span>
                                                                    <section
                                                                        class="staffing-requests-grid--unplanned--role-wrapper">
                                                                        <section
                                                                            class="staffing-requests-grid--unplanned--checkbox">
                                                                            <fieldset>
                                                                                <div [hidden]="!role.enableResourceActions"
                                                                                    class="input__checkbox">
                                                                                    <input type="checkbox"
                                                                                        [attr.aria-label]="'Select Role'+role.resourceId"
                                                                                        class="input__checkbox--focus"
                                                                                        #selectRole value="None"
                                                                                        [id]="role.resourceId"
                                                                                        [name]="role.resourceId"
                                                                                        (change)="onDemandChange($event, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                    <label
                                                                                        [for]="role.resourceId"></label>
                                                                                </div>
                                                                            </fieldset>
                                                                        </section>
                                                                        <section
                                                                            class="staffing-requests-grid--unplanned--user-photo-wrapper">
                                                                            <section
                                                                                class="userPhoto userPhoto--dashed-border"
                                                                                *ngIf="!role.resourcePhoto">
                                                                                <span
                                                                                    class="icon icon-user icon--big"></span>
                                                                            </section>
                                                                            <section class="userPhoto"
                                                                                *ngIf="role.resourcePhoto">
                                                                                <img [src]="role.resourcePhoto"
                                                                                    alt="role.resourceAlias">
                                                                            </section>
                                                                        </section>
                                                                        <section
                                                                            class="staffing-requests-grid--unplanned--role-details">
                                                                            <p>
                                                                                <a target="blank" *ngIf="role.resourceName"
                                                                                    [attr.aria-label]="'Role Details'+ role.resourceId"
                                                                                    [href]="'#/profile/' + role.resourceAlias + '/basic'"
                                                                                    [innerHTML]="role.resourceName ? role.resourceName : (role.resourceAlias) | highlight : searchText">
                                                                                </a>
                                                                            </p>
                                                                            <p
                                                                                [innerHTML]="role.resourceRole | highlight : searchText">
                                                                            </p>
                                                                            <p>
                                                                                Resource ID:
                                                                                <span
                                                                                    [innerHTML]="role.resourceId | highlight : searchText"></span>
                                                                            </p>
                                                                        </section>
                                                                    </section>
                                                                </th>
                                                                <td class="width-6" role="gridcell"
                                                                    *ngIf="isInternalEngagement === false">
                                                                    <span class="sr-only">Bill rate (USD/hr)</span>
                                                                    <span class="grid-data-position"
                                                                        [innerHTML]="(role.resourceBillRate
                                                                                ? (role.resourceBillRate | number: '1.2-2') : '0.00')| highlight : searchText">
                                                                    </span>
                                                                </td>
                                                                <td class="width-6" role="gridcell">
                                                                    <span class="sr-only">Cost rate (USD/hr)</span>
                                                                    <span class="grid-data-position"
                                                                        [innerHTML]="role.resourceCostRate
                                                                                | number: '1.2-2'| highlight : searchText"></span>
                                                                </td>
                                                                <td class="width-6" role="gridcell">
                                                                    <span class="sr-only">Planned (CFP) labor
                                                                        (hrs)</span>
                                                                    <span class="grid-data-position"
                                                                        [innerHTML]="(role.resourcePlannedQuantity
                                                                            ? role.resourcePlannedQuantity : '0')| highlight : searchText"></span>
                                                                </td>
                                                                <td class="width-6" role="gridcell">
                                                                    <span class="sr-only">Staffed (GRM) labor
                                                                        (hrs)</span>
                                                                    <span class="grid-data-position"
                                                                        [innerHTML]="(role.resourceStaffedQuantity
                                                                            ? role.resourceStaffedQuantity : '0')| highlight : searchText"></span>
                                                                </td>
                                                                <td class="width-6" role="gridcell">
                                                                    <span class="sr-only">Planned cost (USD)</span>
                                                                    <span class="grid-data-position" [innerHTML]="(role.resourcePlannedCost
                                                                            ? (role.resourcePlannedCost | number: '1.2-2') :
                                                                            '0.00')| highlight : searchText"></span>
                                                                </td>
                                                                <td class="width-7" role="gridcell">
                                                                    <span class="sr-only">Staffed cost (USD)</span>
                                                                    <span class="grid-data-position"
                                                                        [innerHTML]="(role.resourceStaffedCost
                                                                                ? (role.resourceStaffedCost | number: '1.2-2') :
                                                                                '0.00')| highlight : searchText"></span>
                                                                </td>
                                                                <td class="width-9" role="gridcell">
                                                                    <span class="sr-only">Duration</span>
                                                                    <span
                                                                        class="grid-data-position">
                                                                        <span class="no-wrap">
                                                                            <span
                                                                                [innerHTML]="role.resourceStartDate |
                                                                                        dmDisplayDateOrDashPipe| highlight : searchText"></span>
                                                                            -
                                                                        </span>
                                                                        <span class="dis-block"
                                                                            [innerHTML]="role.resourceEndDate |
                                                                                    dmDisplayDateOrDashPipe| highlight : searchText"></span>
                                                                    </span>
                                                                </td>
                                                                <td class="width-6" role="gridcell">
                                                                    <span class="sr-only">Cost variation</span>
                                                                    <span class="grid-data-position">
                                                                        <span
                                                                            [innerHTML]="(role.resourceCostVariance + '%') | highlight : searchText"></span>
                                                                    </span>
                                                                </td>
                                                                <td class="width-8" role="gridcell">
                                                                    <span class="sr-only">Status</span>
                                                                    <span class="grid-data-position"
                                                                        [ngClass]="{'font-red':role.resourceStatus === 'Draft'}">
                                                                        <span
                                                                            [innerHTML]="role.resourceStatus | highlight : searchText"></span>
                                                                        <span *ngIf="role.resourceSubStatus">
                                                                            -
                                                                            <span
                                                                                [innerHTML]="role.resourceSubStatus | highlight : searchText"></span>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                                <td class="width-9" role="gridcell">
                                                                    <span class="sr-only">Actions</span>
                                                                    <span class="grid-data-position grid-data-position--action">
                                                                        <section class="action-btns">
                                                                            <div *ngIf="role.enableResourceActions">
                                                                                <section *ngIf="isInternalEngagement || showUSPubSecRMActions">
                                                                                    <a class="pull-left"
                                                                                        [attr.aria-label]="'Edit Draft Role'+ role.resourceId"
                                                                                        *ngIf="role.resourceActions ==='Edit' && role.resourceStatus === 'Draft'"
                                                                                        title="Edit Role"
                                                                                        [href]="'#/project/' + role.resourceGrmProjectID + '/resource/' + role.resourceId + '/true/requestor'"
                                                                                        (click)="logEditRoleClick()">
                                                                                        <span
                                                                                            class="icon icon-editLegacy"></span>
                                                                                    </a>
                                                                                    <a class="pull-left"
                                                                                        [attr.aria-label]="'Edit role'+ role.resourceId"
                                                                                        *ngIf="role.resourceActions ==='Edit' && role.resourceStatus !== 'Draft'"
                                                                                        title="Edit Role"
                                                                                        [href]="'#/project/' + role.resourceGrmProjectID + '/resource/' + role.resourceId + '/false/requestor'"
                                                                                        (click)="logEditRoleClick()">
                                                                                        <span
                                                                                            class="icon icon-editLegacy"></span>
                                                                                    </a>
                                                                                </section>
                                                                                <section *ngIf="!isInternalEngagement && !showUSPubSecRMActions">
                                                                                    <a class="pull-left"
                                                                                        [attr.aria-label]="'Edit role'+ role.resourceId"
                                                                                        *ngIf="role.resourceActions ==='Edit'"
                                                                                        title="Edit Role"
                                                                                        [href]="'#/workbench/Requestor?requestId=' + role.resourceId"
                                                                                        (click)="logEditRoleClick()">
                                                                                        <span
                                                                                            class="icon icon-editLegacy"></span>
                                                                                    </a>
                                                                                </section>
                                                                            </div>
                                                                            <section class="pull-left"
                                                                                *ngIf="role.resourceActions==='AcceptDecline'">
                                                                                <section>
                                                                                    <button type="button"
                                                                                        title="Accept Role"
                                                                                        (click)="sendEventEmitter(role, 'Accept', item.taskProjectId)">
                                                                                        <span
                                                                                            class="icon icon-CheckMark"></span>
                                                                                    </button>
                                                                                </section>
                                                                                <section>
                                                                                    <button type="button"
                                                                                        title="Reject Role"
                                                                                        (click)="sendEventEmitter(role, 'Decline', item.taskProjectId)">
                                                                                        <span
                                                                                            class="icon icon-rejectLegacy"></span>
                                                                                    </button>
                                                                                </section>
                                                                            </section>
                                                                            <section class="dropdown pull-right">
                                                                                <button type="button"
                                                                                    data-toggle="dropdown"
                                                                                    title="Role Actions Dropdown"
                                                                                    [attr.aria-label]="'Role Actions Dropdown'+role.resourceId"
                                                                                    id="{{'Role Actions Dropdown '+role.resourceId}}"
                                                                                    (click)="logDropdownClick()"
                                                                                    (keydown.ArrowDown)="moveFocus(role.resourceId  , ' accessibilityConstants.Split')">
                                                                                    <span
                                                                                        class="icon-full icon-more-vertical"></span>
                                                                                </button>
                                                                                <div
                                                                                    class="dropdown-menu dropdown-menu-right font-caption-alt">
                                                                                    <button class="dropdown-item"
                                                                                        *ngIf="commandBarActionsContains('SplitRole')"
                                                                                        id="{{ 'staffing-grid-' + role.resourceId + ' accessibilityConstants.Split'}}"
                                                                                        (keydown.ArrowDown)="moveFocus(role.resourceId  , ' accessibilityConstants.Remove')"
                                                                                        (click)="splitPlannedPopup(role, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                        <i
                                                                                            class="icon grphic-icons-font-split"></i>
                                                                                        Split Role
                                                                                    </button>
                                                                                    <button href class="dropdown-item"
                                                                                        *ngIf="commandBarActionsContains('RemoveRole')"
                                                                                        id="{{ 'staffing-grid-' + role.resourceId + ' accessibilityConstants.Remove'}}"
                                                                                        (keydown.ArrowUp)="moveFocus(role.resourceId  , ' accessibilityConstants.Split')"
                                                                                        (keydown.ArrowDown)="moveFocus(role.resourceId  , 'accessibilityConstants.CopyRequest')"
                                                                                        (click)="submitRemoveRole(role, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                        <span
                                                                                            class="glyphicon glyphicon-minus-sign"></span>
                                                                                        Remove Role
                                                                                    </button>
                                                                                    <button href class="dropdown-item"
                                                                                        *ngIf="commandBarActionsContains('Copy') && !isInternalEngagement"
                                                                                        id="{{ 'staffing-grid-' + role.resourceId + ' accessibilityConstants.CopyRequest'}}"
                                                                                        (keydown.ArrowUp)="moveFocus(role.resourceId  , ' accessibilityConstants.Remove')"
                                                                                        (keydown.ArrowDown)="moveFocus(role.resourceId  , (role.resourceStatus === 'Suspended'? ' Unsuspend': ' Suspend'))"
                                                                                        (click)="loadCopyResourcePopup(role, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                        <span
                                                                                            class="icon-full icon-copy"></span>
                                                                                        Copy
                                                                                    </button>
                                                                                    <button href
                                                                                        *ngIf="role.resourceStatus !== 'Suspended' && commandBarActionsContains('SuspendRole')"
                                                                                        class="dropdown-item"
                                                                                        id="{{ 'staffing-grid-' + role.resourceId + ' Suspend'}}"
                                                                                        (keydown.ArrowUp)="moveFocus(role.resourceId  , ' accessibilityConstants.CopyRequest')"
                                                                                        (keydown.ArrowDown)="moveFocus(role.resourceId  , ' accessibilityConstants.RequestRole')"
                                                                                        (click)="loadSuspendPopup(role, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                        <i
                                                                                            class="icon icon-InPrivate"></i>
                                                                                        Suspend
                                                                                    </button>
                                                                                    <button href
                                                                                        *ngIf="role.resourceStatus === 'Suspended' && commandBarActionsContains('UnsuspendRole')"
                                                                                        class="dropdown-item"
                                                                                        id="{{ 'staffing-grid-' + role.resourceId + ' Unsuspend'}}"
                                                                                        (keydown.ArrowUp)="moveFocus(role.resourceId  , ' accessibilityConstants.Remove')"
                                                                                        (keydown.ArrowDown)="moveFocus(role.resourceId  , ' accessibilityConstants.RequestRole')"
                                                                                        (click)="loadUnsuspendPopup(role, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                        <i
                                                                                            class="icon grphic-icons-font-unsuspend"></i>
                                                                                        Unsuspend Role
                                                                                    </button>
                                                                                    <button href class="dropdown-item"
                                                                                        *ngIf="commandBarActionsContains('RoleMaintainance')"
                                                                                        id="{{ 'staffing-grid-' + role.resourceId + ' accessibilityConstants.RequestRole'}}"
                                                                                        (keydown.ArrowUp)="moveFocus(role.resourceId  , (role.resourceStatus === 'Suspended'? ' Unsuspend' : ' Suspend'))"
                                                                                        (keydown.ArrowDown)="moveFocus(role.resourceId  , ' accessibilityConstants.Cancel')"
                                                                                        (click)="loadRquestRoleMaintenancePopup(role, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                        <i class="icon icon-Repair"></i>
                                                                                        Request Role Maintenance
                                                                                    </button>
                                                                                    <button href class="dropdown-item"
                                                                                        *ngIf="commandBarActionsContains('Cancel')"
                                                                                        id="{{ 'staffing-grid-' + role.resourceId + ' accessibilityConstants.Cancel'}}"
                                                                                        (keydown.ArrowUp)="moveFocus(role.resourceId  , ' accessibilityConstants.RequestRole')"
                                                                                        (keydown.ArrowDown)="moveFocus(role.resourceId  , ' accessibilityConstants.Truncate')"
                                                                                        (click)="loadCancelPopup(role, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                        <i
                                                                                            class="icon icon-cancelN"></i>
                                                                                        Cancel
                                                                                    </button>
                                                                                    <button href class="dropdown-item"
                                                                                        *ngIf="commandBarActionsContains('TruncateRole')"
                                                                                        id="{{ 'staffing-grid-' + role.resourceId + ' accessibilityConstants.Truncate'}}"
                                                                                        (keydown.ArrowUp)="moveFocus(role.resourceId  , ' accessibilityConstants.Cancel')"
                                                                                        (keydown.Tab)="closeDropdownOnTab($event.target.id)"
                                                                                        (click)="truncatePopup(role, item.taskProjectId, request.demandId, role.resourceId)">
                                                                                        <i
                                                                                            class="icon icon-pictureTruncate"></i>
                                                                                        Truncate
                                                                                    </button>
                                                                                </div>
                                                                            </section>
                                                                        </section>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </ng-container>
                        </div>
                        <p *ngIf="!item.showLoading && (!item.taskModelDetails || item.taskModelDetails.length === 0)"
                            class="font-subtitle dm-tile--noRecords text-center">
                            No Tasks have been created against this project.
                        </p>
                    </ng-container>
                </div>
            </div>
            </tbody>
            </table>
        </section>
        <section class="col-lg-12 col-md-12 col-sm-12 col-xs-12 data-text">
            <dm-no-data *ngIf="showNoDemandsMessage" [noDataText]="noDemandsMessageText"></dm-no-data>
            <dm-no-data *ngIf="isProjectMarkedForDeletion" [noDataText]="projectMarkedForDeletion"></dm-no-data>
        </section>
    </section>
</section>